import React from 'react';
import { Error } from 'components/Error';
import { Helmet } from 'react-helmet';
import { getHomepageUrl } from 'helpers/General';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
        <meta name="description" content="The page was not found." />
      </Helmet>
      <Error
        code="404"
        errorMessage="Page Not Found"
        secondaryButtonText="Return to Home Page"
        secondaryButtonHref={getHomepageUrl()}
      />
    </>
  );
};
export default NotFound;
